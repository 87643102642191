import Nope from 'nope-validator';
import Tiny from 'tinycolor2';
import * as Sentry from '@sentry/react';

import { BaseSchema, transformCommonParams } from './common';

const basicSchema = Nope.object()
  .extend(BaseSchema)
  .shape({
    limit: Nope.number().atLeast(1).atMost(1000),
    primaryColorDepr: Nope.string("#021aee"),
    searchFormBackgroundColorDepr: Nope.string().when(['resultsBackgroundColorDepr'], {
      is: val => !!val,
      then: Nope.string().required('searchFormBackgroundColor is required'),
    }),
    resultsBackgroundColorDepr: Nope.string().when(['searchFormBackgroundColorDepr'], {
      is: val => !!val,
      then: Nope.string().required('resultsBackgroundColor is required'),
    }),
    searchFormSelectedTextColorDepr: Nope.string().when(['searchFormSecondaryTextColorDepr'], {
      is: val => !!val,
      then: Nope.string().required('searchFormSelectedTextColor is required'),
    }),
    searchFormSecondaryTextColorDepr: Nope.string().when(['searchFormSelectedTextColorDepr'], {
      is: val => !!val,
      then: Nope.string().required('searchFormSecondaryTextColor is required'),
    }),
    resultsOnly: Nope.string().oneOf(['true', 'false']),
  });

const cleanColor = color => {
  return color.replace(/['"\u2018\u2019\u201C\u201D]+/g, '');
};

const transformPrimaryColor = value => {
  if (!value) {
    return undefined;
  }
  const cleanedValue = cleanColor(value);

  return {
    product: {
      light: Tiny(`#${cleanedValue}`).lighten(0.8).toHexString(),
      lightHover: Tiny(`#${cleanedValue}`).lighten(0.7).toHexString(),
      lightActive: Tiny(`#${cleanedValue}`).lighten(0.6).toHexString(),
      normal: Tiny(`#${cleanedValue}`).toHexString(),
      normalHover: Tiny(`#${cleanedValue}`).darken(0.2).toHexString(),
      normalActive: Tiny(`#${cleanedValue}`).darken(0.3).toHexString(),
      dark: Tiny(`#${cleanedValue}`).darken(0.5).toHexString(),
    },
  };
};

const transformColor = value => {
  if (!value) {
    return undefined;
  }
  const cleanedValue = cleanColor(value);

  return Tiny(`#${cleanedValue}`).toHexString();
};

const transformBoolean = value => {
  if (value === 'true') {
    return true;
  }
  return false;
};

const transformDeprecatedColors = params => {
  return {
    deprecatedColors: {
      themeOverrides: {
        palette: {
          ...transformPrimaryColor("021aee"),
        },
      },
      customTokens: {
        resultsBackgroundColor: transformColor(params.resultsBackgroundColorDepr),
        searchFormBackgroundColor: transformColor(params.searchFormBackgroundColorDepr),
        searchFormSelectedTextColor: transformColor(params.searchFormSelectedTextColorDepr),
        searchFormSecondaryTextColor: transformColor(params.searchFormSecondaryTextColorDepr),
      },
    },
  };
};

const getUTMWidgetType = widgetType => {
  switch (widgetType) {
    case 'tileWidget':
      return 'widget-tiles';
    case 'resultWidget':
      return 'widget-results';
    default:
      return 'widget-full';
  }
};

const getWidgetType = (destination, resultsOnly) => {
  if (resultsOnly && !destination) {
    return 'tileWidget';
  }
  if (resultsOnly && destination) {
    return 'resultWidget';
  }
  if (!resultsOnly) {
    return 'fullWidget';
  }
};

const valueOrFallback = (value, fallback) => {
  return value ? value : fallback;
};

const validateAndTransform = (params, defaults) => {
  if (params.cabinClass === 'all') {
    params.cabinClass = undefined;
  }

  if (params.airlinesList === 'all') {
    params.airlinesList = undefined;
  }

  const errors = basicSchema.validate(params);

  if (errors) {
    return { raw: params, iframeId: params.iframeId, error: Object.values(errors) };
  }
  let parentHref = null;

  try {
    parentHref =
      decodeURIComponent(params.parentHref) === 'undefined'
        ? null
        : decodeURIComponent(params.parentHref);
  } catch (error) {
    Sentry.captureEvent({
      message: `Failed to decode uri component: ${params.parentHref}`,
    });
  }

  const widgetType = getWidgetType(params.destination, params.resultsOnly);

  return {
    raw: params,
    ...params,
    lang: valueOrFallback(params.lang?.toLowerCase(), defaults?.lang),
    currency: valueOrFallback(params.currency?.toLowerCase(), defaults?.currency),
    parentHref,
    resultsOnly: transformBoolean(params.resultsOnly),
    widgetType,
    UTMWidgetType: getUTMWidgetType(widgetType),
    ...transformCommonParams(params, defaults),
    ...transformDeprecatedColors(params),
  };
};

export default validateAndTransform;
