import { useMemo, useContext } from 'react';

import { rejectNil } from '../../../../utils/object';
import { getHoldBags, getHandBags } from '../../../../utils/bags';
import { convertPrice } from '../../../../utils/price';
import { adjustForQuery } from '../../../../utils/datetime';
import {SearchState}  from '../../../../services/filters/Search/SearchProvider';
import usePaxAndBags from '../../../../services/filters/PassengersAndBags/usePaxAndBags';
import  useSearchFormState  from '../../../../scenes/Basic/services/SearchForm/useSearchForm';
import {SearchFormDispatch}  from '../../../../scenes/Basic/services/SearchForm/SearchFormProvider';
import  useTranslations  from '../../../../services/translations/useTranslations';
import useCurrency  from '../../../../services/currencies/useCurrency';
import useEntryParameters from '../../../../services/entryParameters/useEntryParameters';
import { SORT_BY_AGGREGATED_VALUES } from '../../../../consts/sortBy';
import { isNightsOfStay } from '../../../../utils/datetime';

import { SearchAggregatedQueryState } from './SearchAggregatedQueryProvider';

const getPriceFilter = (price, currencyData) => {
  if (price[0] === 0 && (price[1] === 0 || price[1] === 3000)) {
    return {};
  }
  return {
    price: rejectNil({
      start: price[0] === 0 ? undefined : Math.round(convertPrice(price[0], currencyData)),
      end:
        price[1] === 0 || price[1] === 3000
          ? undefined
          : Math.round(convertPrice(price[1], currencyData)),
    }),
  };
};

const getDates = dates => {
  if (dates.start.length) {
    return dates;
  }
  return { ...adjustForQuery([new Date()]) };
};
const generateVariables = ({
  source,
  destination,
  outboundDate,
  inboundDate,
  sortBy,
  transportTypes,
  cabinClass,
  applyMixedClasses,
  adults,
  children,
  infants,
  cabinBags,
  checkedBags,
  price,
  currencyData,
  lang,
  locationHashtags,
  outboundDays,
  inboundDays,
  currency,
  mode,
  allowOvernightStopovers,
  stopNumber,
  limit,
  tileLimit,
  carriers,
  excludeCarriers,
  affilid,
  sub2,
  duration,
  returnFromDifferentAirport,
  returnToDifferentAirport,
}) => ({
  search: {
    itinerary: {
      source: {
        ids: source.map(s => s.code),
      },
      destination: {
        ids: destination?.map(s => s.code) || [],
      },
      ...(outboundDate !== 'anytime' && { outboundDepartureDate: getDates(outboundDate) }),
      ...(mode === 'return' &&
        !isNightsOfStay(inboundDate) &&
        inboundDate !== 'anytime' && { inboundDepartureDate: getDates(inboundDate) }),
      ...(isNightsOfStay(inboundDate) && {
        nightsCount: { start: +inboundDate.split('-')[0], end: +inboundDate.split('-')[1] },
      }),
    },
    passengers: {
      adults,
      children,
      infants,
      ...getHandBags(adults, children, cabinBags),
      ...getHoldBags(adults, children, checkedBags),
    },
    cabinClass: {
      cabinClass,
      applyMixedClasses,
    },
  },
  filter: {
    allowChangeInboundDestination: returnToDifferentAirport,
    allowChangeInboundSource: returnFromDifferentAirport,
    showNoCheckedBags: true,
    allowOvernightStopover: allowOvernightStopovers,
    ...(allowOvernightStopovers && stopNumber !== -1 && { maxStopsCount: stopNumber }),
    transportTypes,
    contentProviders: ['KIWI', 'FRESH'], // ['KIWI', 'FLIXBUS_DIRECTS', 'FRESH', 'KAYAK', 'BUSBUD', 'LASTMINUTE']
    ...(tileLimit ? { limit: +tileLimit } : { limit: +limit }),
    maxDuration: duration,
    ...getPriceFilter(price, currencyData),
    locationHashtags,
    // If one or more days are deselected they won't be removed but their value should be null
    ...(outboundDays.includes(null) && {
      outbound: {
        days: outboundDays.filter(Boolean),
      },
    }),
    ...(mode !== 'one-way' &&
      inboundDays.includes(null) && {
        inbound: { days: inboundDays.filter(Boolean) },
      }),
    ...(carriers && { carriers }),
    ...(excludeCarriers && { excludeCarriers }),
  },
  options: {
    sortBy: SORT_BY_AGGREGATED_VALUES[sortBy],
    currency,
    locale: lang,
    partner: affilid || 'skypicker',
    partnerMarket: sub2 || lang,
    affilID: affilid || 'skypicker',
  },
});

const useAggregatedSearch = () => {

  const [nativeLoadResults, results] = useContext(SearchAggregatedQueryState);
  const { adults, children, infants, cabinBags, checkedBags } = usePaxAndBags();
  const {
    aggregatedSortBy,
    transportTypes,
    cabinClass,
    applyMixedClasses,
    price,
    locationHashtags,
    outboundDays,
    inboundDays,
    allowOvernightStopovers,
    stopNumber,
    limit,
    tileLimit,
    carriers,
    excludeCarriers,
    returnFromDifferentAirport,
    returnToDifferentAirport,
  } = useContext(SearchState);
  const { mode, source, destination, outboundDate, inboundDate } = useSearchFormState();
  const { activeCurrencyCode, currencies } = useCurrency();
  const { lang } = useTranslations();
  const { affilid, duration, sub2 } = useEntryParameters();
  const { setLatestSearch } = useContext(SearchFormDispatch);

  const currencyData = currencies?.find?.(c => c.code === activeCurrencyCode?.toLowerCase?.());

  const variables = useMemo(
    () =>
      generateVariables({
        source,
        destination,
        outboundDate,
        inboundDate,
        sortBy: aggregatedSortBy,
        transportTypes,
        cabinClass,
        applyMixedClasses,
        adults,
        children,
        infants,
        cabinBags,
        checkedBags,
        price,
        currencyData,
        lang,
        locationHashtags,
        outboundDays,
        inboundDays,
        currency: activeCurrencyCode,
        mode,
        allowOvernightStopovers,
        stopNumber,
        limit,
        tileLimit,
        duration,
        carriers,
        excludeCarriers,
        affilid,
        sub2,
        returnFromDifferentAirport,
        returnToDifferentAirport,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      source,
      destination,
      outboundDate,
      inboundDate,
      aggregatedSortBy,
      transportTypes,
      cabinClass,
      applyMixedClasses,
      adults,
      children,
      infants,
      cabinBags,
      checkedBags,
      price,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      JSON.stringify(currencyData),
      lang,
      locationHashtags,
      outboundDays,
      inboundDays,
      activeCurrencyCode,
      mode,
      allowOvernightStopovers,
      stopNumber,
      limit,
      tileLimit,
      carriers,
      excludeCarriers,
      affilid,
      sub2,
      duration,
      returnFromDifferentAirport,
      returnToDifferentAirport,
    ],
  );

  const loadResults = () => {
      setLatestSearch();
      nativeLoadResults({ variables });

  };

  if (mode === 'return') {
    return {
      results: {
        ...results,
        data: results.data?.returnOnePerCityItineraries,
      },
      loadResults,
    };
  }

  return {
    results: {
      ...results,
      data: results.data?.onewayOnePerCityItineraries,
    },
    loadResults,
  };
};

export default useAggregatedSearch;
