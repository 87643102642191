import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';

import { mapPlaceEdgeToPlace } from '../../utils/place';
import PLACES_QUERY from '../../graphql/queries/places.graphql';
import basicValidateAndTransform from './schemas/basic';


export const EntryParametersState = React.createContext({});
export const EntryParametersDispatch = React.createContext({});

const entryParamsInitialState =  basicValidateAndTransform({affilid:'ninoairlines',currency:'EUR'});;

const EntryParametersProvider = ({ children }) => {
  const [entryParams, setEntryParams] = useState(entryParamsInitialState);
  const [loadPlaces, results] = useLazyQuery(PLACES_QUERY);
  useEffect(() => {
    if (!entryParams.error) {
      const sources = entryParams.source?.filter?.(e => typeof e === 'string');
      const destinations = entryParams.destination?.filter?.(e => typeof e === 'string');

      loadPlaces({
        variables: {
          search: {
            ids: [sources, destinations].filter(Boolean).flat(Infinity),
          },
          options: {
            locale: entryParams.lang,
          },
        },
      });
    }
  }, [
    entryParams.error,
    entryParams.source,
    entryParams.destination,
    entryParams.lang,
    loadPlaces,
  ]);

  if (entryParams.error) {
    return (
      <EntryParametersDispatch.Provider value={setEntryParams}>
        <EntryParametersState.Provider value={entryParams}>
          {children}
        </EntryParametersState.Provider>
      </EntryParametersDispatch.Provider>
    );
  }

  const data = results?.data?.places?.edges.map(mapPlaceEdgeToPlace);

  const getStationParam = param => {
    return param && data
      ? param.map(s => {
          // for deeplink generator sync, it puts whole objects
          if (typeof s === 'object') {
            return s;
          }

          return data.find(p => s === p.id);
        })
      : [];
  };
  const source = getStationParam([...new Set(entryParams.source)]).filter(i => i);
  const destination = getStationParam([...new Set(entryParams.destination)]).filter(i => i);

  const value = {
    ...entryParams,
    loadingPlaces: results.called && results.loading,
    source,
    destination,
  };

  return (
    <EntryParametersDispatch.Provider value={setEntryParams}>
      <EntryParametersState.Provider value={value}>{children}</EntryParametersState.Provider>
    </EntryParametersDispatch.Provider>
  );
};

export default EntryParametersProvider;
