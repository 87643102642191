import { useMemo, useRef, useState, useContext, useEffect } from 'react';

import { getHoldBags, getHandBags } from '../../../../utils/bags';
import { rejectNil } from '../../../../utils/object';
import { convertPrice } from '../../../../utils/price';
import { adjustForQuery } from '../../../../utils/datetime';
import {SearchState}  from '../../../../services/filters/Search/SearchProvider';
import usePaxAndBags from '../../../../services/filters/PassengersAndBags/usePaxAndBags';
import  useSearchFormState  from '../../../../scenes/Basic/services/SearchForm/useSearchForm';
import {SearchFormDispatch}  from '../../../../scenes/Basic/services/SearchForm/SearchFormProvider';
import  useTranslations  from '../../../../services/translations/useTranslations';
import useCurrency  from '../../../../services/currencies/useCurrency';
import useEntryParameters from '../../../../services/entryParameters/useEntryParameters';
import { SORT_BY_VALUES } from '../../../../consts/sortBy';
import { isNightsOfStay } from '../../../../utils/datetime';

import { SearchQueryState } from './SearchQueryProvider';

const getPriceFilter = (price, currencyData) => {
  if (price[0] === 0 && (price[1] === 0 || price[1] === 3000)) {
    return {};
  }
  return {
    price: rejectNil({
      start: price[0] === 0 ? undefined : Math.round(convertPrice(price[0], currencyData)),
      end:
        price[1] === 0 || price[1] === 3000
          ? undefined
          : Math.round(convertPrice(price[1], currencyData)),
    }),
  };
};

const getDates = dates => {
  if (dates.start.length) {
    return dates;
  }
  return { ...adjustForQuery([new Date()]) };
};

const getTimes = times => {
  if (!times) {
    return {};
  }

  return {
    ...(times.departure[0] === 0 && times.departure[1] === 24
      ? {}
      : {
          departureHours: {
            start: times.departure[0],
            end: times.departure[1],
          },
        }),
    ...(times.arrival[0] === 0 && times.arrival[1] === 24
      ? {}
      : {
          arrivalHours: {
            start: times.arrival[0],
            end: times.arrival[1],
          },
        }),
  };
};

const getDays = days => {
  if (!days.includes(null)) {
    return {};
  }

  return { days: days.filter(Boolean) };
};

const generateVariables = ({
  source,
  destination,
  outboundDate,
  inboundDate,
  sortBy,
  transportTypes,
  cabinClass,
  applyMixedClasses,
  adults,
  children,
  infants,
  cabinBags,
  checkedBags,
  price,
  currencyData,
  lang,
  currency,
  outboundDays,
  inboundDays,
  mode,
  allowOvernightStopovers,
  stopNumber,
  limit,
  carriers,
  excludeCarriers,
  affilid,
  sub2,
  times,
  returnFromDifferentAirport,
  returnToDifferentAirport,
}) => {
  const timesDefault = '024';
  const isTimesOutboundChanged =
    times.outbound.departure.join('') !== timesDefault ||
    times.outbound.arrival.join('') !== timesDefault;

  const isTimesInboundChanged =
    times.inbound.departure.join('') !== timesDefault ||
    times.inbound.arrival.join('') !== timesDefault;

  return {
    search: {
      itinerary: {
        source: {
          ids: source.map(s => s.code),
        },
        destination: {
          ids: destination?.map(s => s.code) || [],
        },
        ...(outboundDate !== 'anytime' && { outboundDepartureDate: getDates(outboundDate) }),
        ...(mode === 'return' &&
          inboundDate !== 'anytime' &&
          !isNightsOfStay(inboundDate) && { inboundDepartureDate: getDates(inboundDate) }),
        ...(isNightsOfStay(inboundDate) && {
          nightsCount: { start: +inboundDate.split('-')[0], end: +inboundDate.split('-')[1] },
        }),
      },
      passengers: {
        adults,
        children,
        infants,
        ...getHandBags(adults, children, cabinBags),
        ...getHoldBags(adults, children, checkedBags),
      },
      cabinClass: {
        cabinClass,
        applyMixedClasses,
      },
    },
    filter: {
      allowChangeInboundDestination: returnToDifferentAirport,
      allowChangeInboundSource: returnFromDifferentAirport,
      showNoCheckedBags: true,
      allowOvernightStopover: allowOvernightStopovers,
      ...(allowOvernightStopovers && stopNumber !== -1 && { maxStopsCount: stopNumber }),
      transportTypes,
      contentProviders: ['KIWI', 'FRESH'], // ['KIWI', 'FLIXBUS_DIRECTS', 'FRESH', 'KAYAK', 'BUSBUD', 'LASTMINUTE']
      limit,
      ...getPriceFilter(price, currencyData),
      ...((outboundDays.includes(null) || isTimesOutboundChanged) && {
        outbound: {
          ...getDays(outboundDays),
          ...getTimes(times?.outbound),
        },
      }),
      ...(mode !== 'one-way' &&
        (inboundDays.includes(null) || isTimesInboundChanged) && {
          inbound: {
            ...getDays(inboundDays),
            ...getTimes(times?.inbound),
          },
        }),
      ...(carriers && { carriers }),
      ...(excludeCarriers && { excludeCarriers }),
    },
    options: {
      sortBy: SORT_BY_VALUES[sortBy],
      currency,
      locale: lang,
      partner: affilid || 'skypicker',
      partnerMarket: sub2 || lang,
      affilID: affilid || 'skypicker',
    },
  };
};

const useSearch = () => {
  const [nativeLoadResults, { data, stopPolling, startPolling, ...rest }] = useContext(
    SearchQueryState,
  );

  const { adults, children, infants, cabinBags, checkedBags } = usePaxAndBags();
  const {
    sortBy,
    transportTypes,
    cabinClass,
    applyMixedClasses,
    price,
    outboundDays,
    inboundDays,
    allowOvernightStopovers,
    stopNumber,
    limit,
    carriers,
    excludeCarriers,
    times,
    returnFromDifferentAirport,
    returnToDifferentAirport,
  } = useContext(SearchState);
  const { mode, source, destination, outboundDate, inboundDate } = useSearchFormState();
  const { lang } = useTranslations();
  const { activeCurrencyCode, currencies } = useCurrency();
  const { affilid, sub2 } = useEntryParameters();
  const isPolling = useRef(false);
  const [loading, setLoading] = useState(false);
  const { setLatestSearch } = useContext(SearchFormDispatch);

  const currencyData = currencies?.find?.(c => c.code === activeCurrencyCode?.toLowerCase?.());
console.log(destination);
  const variables = useMemo(
    () =>
      generateVariables({
        source,
        destination,
        outboundDate,
        inboundDate,
        sortBy,
        transportTypes,
        cabinClass,
        applyMixedClasses,
        adults,
        children,
        infants,
        cabinBags,
        checkedBags,
        price,
        lang,
        currency: activeCurrencyCode,
        currencyData,
        outboundDays,
        inboundDays,
        mode,
        allowOvernightStopovers,
        stopNumber,
        limit,
        carriers,
        excludeCarriers,
        affilid,
        sub2,
        times,
        returnFromDifferentAirport,
        returnToDifferentAirport,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      source,
      destination,
      outboundDate,
      inboundDate,
      sortBy,
      transportTypes,
      cabinClass,
      applyMixedClasses,
      adults,
      children,
      infants,
      cabinBags,
      checkedBags,
      price,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      JSON.stringify(currencyData),
      lang,
      activeCurrencyCode,
      outboundDays,
      inboundDays,
      mode,
      allowOvernightStopovers,
      stopNumber,
      limit,
      carriers,
      excludeCarriers,
      affilid,
      sub2,
      times,
      returnFromDifferentAirport,
      returnToDifferentAirport,
    ],
  );
   // console.log(variables.search);
   /* if (window.location.hash.replace("#",'')) variables.search.itinerary.source.ids[0]=window.location.hash.replace("#",'');
    else {
        window.location.hash=variables.search.itinerary.source.ids[0];
    }*/

  useEffect(() => {
    if (!data) {
      return;
    }

    //stop polling if received data >= limit or hasMorePending=false
    if (data.onewayItineraries) {
      if (
        data.onewayItineraries?.itineraries.length < limit &&
        data.onewayItineraries?.metadata.itinerariesCount < limit &&
        data.onewayItineraries?.metadata.hasMorePending
      ) {
        startPollingResults();
        return;
      }
      if (isPolling.current) {
        stopPollingResults();
        return;
      }
    }

    if (data.returnItineraries) {
      if (
        data.returnItineraries?.itineraries.length < limit &&
        data.returnItineraries?.metadata.itinerariesCount < limit &&
        data.returnItineraries?.metadata.hasMorePending
      ) {
        startPollingResults();
        return;
      }
      if (isPolling.current) {
        stopPollingResults();
        return;
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data?.onewayItineraries?.itineraries?.length,
    data?.onewayItineraries?.metadata?.hasMorePending,
    data?.returnItineraries?.metadata?.hasMorePending,
    data?.returnItineraries?.itineraries?.length,
  ]);

  const loadResults = () => {
    // This happens on destination click and because of that destination can't be empty
    if (!destination.length || rest.loading) {
      return;
    }
    setLatestSearch();
    nativeLoadResults({ variables });
  };

  const startPollingResults = () => {
    isPolling.current = true;
    setLoading(true);
    startPolling(5000);
  };

  const stopPollingResults = () => {
    isPolling.current = false;
    setLoading(false);
    stopPolling();
  };

  useEffect(() => {
    return () => {
      if (stopPolling) {
        stopPolling();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    mode,
    sortBy,
    cabinBags,
    checkedBags,
    price,
    transportTypes,
    outboundDays,
    inboundDays,
    allowOvernightStopovers,
    stopNumber,
    times,
    returnFromDifferentAirport,
    returnToDifferentAirport,
    activeCurrencyCode,
  ]);

  if (mode === 'return') {
    return {
      results: {
        ...rest,
        data: data?.returnItineraries,
        loadingMore: loading || rest.loading,
      },
      loadResults,
    };
  }

  return {
    results: {
      ...rest,
      data: data?.onewayItineraries,
      loadingMore: loading || rest.loading,
    },
    loadResults,
  };
};

export default useSearch;
